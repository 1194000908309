<template>
  <div>
    <v-row align="start">
      <v-col sm="12" md="12">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>Pagos</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="recepitSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.receipt)"
                >
                  Crear Pago
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.receipt"
              :items="receipt_records"
              :items-per-page="-1"
              multi-sort
              item-key="id"
              class=" rounded-xl"
              :search="recepitSearch"
              :loading="loadingReceiptTable"
              sort-by="id"
              sort-desc=""
              loading-text="Loading... Please wait"
            >
              <template v-slot:item.date="{ item }">
                <tr>
                  <td>
                    <span >{{`${item.date.substring(0,10)}`}}</span>
                  </td>
                </tr>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon color="primary" small class="mr-2" @click="editItem(item, boards.receipt)">
                  mdi-magnify-plus
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.receipt)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Receipt Form -->
    <v-row justify="center" >
      <v-form ref="createReceiptForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogRecepit" persistent max-width="600px">
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Pago ${createReceiptForm.date}` : `Crear Pago` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <!-- Metodo de pago y factura -->
                <v-row dense>
                  <v-col xs="12" md="4">                   
                    <v-autocomplete
                      class="text-caption"
                      v-model="createReceiptForm.payment_method"
                      item-value="id"
                      dense
                      :items="paymentMethod_records"
                      item-text="name"
                      label="Método de pago"
                      required
                      :rules="[createFormRules.required]"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col xs="12" md="8">       
                    <v-autocomplete
                      class="text-caption"
                      v-model="createReceiptForm.folio"
                      item-value="id"
                      dense
                      :items="facturasPendientes"
                      :item-text="folioTextName"
                      label="Factura a pagar"
                      required
                      :rules="[createFormRules.required]"
                      clearable
                      @input="verificarFactura(createReceiptForm.folio)"
                    ></v-autocomplete>
                  </v-col>
                </v-row>                
                <!-- Monto fecha -->
                <v-row dense>
                  <v-col xs="12" md="5">
                    <v-text-field
                      dense
                      label="Monto"
                      v-model="createReceiptForm.amount"
                      :rules="[createFormRules.required]"
                      type="number"
                      value='0.00' lang="en"
                      min="0"
                      val
                      @input="propinas"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col xs="12" md="6" class="mr-2">
                    <v-menu
                      v-model="receiptDatePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      required
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          class="py-1"
                          v-model="createReceiptForm.date"
                          label="Fecha"
                          prepend-icon="mdi-calendar"
                          readonly
                          :rules="[createFormRules.required]"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="createReceiptForm.date"
                        @input="receiptDatePicker = false"
                        :rules="[createFormRules.required]"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <!-- Informacion factura -->
                <v-row dense align="center">
                  <v-col cols="3" >
                    <div class="d-flex-row">
                      <p class="ma-0"><strong>Total Fact:</strong></p>
                      <p class="ma-0"><strong>Pago:</strong> </p>
                      <p class="ma-0"><strong>Balance:</strong></p>
                    </div>
                   
                  </v-col>
                  <v-col cols="3" >
                    <div class="d-flex-row">
                      <p class="ma-0 text-right">{{`$ ${facturaPagar.total ? facturaPagar.total: 0}`}}</p>
                      <p class="ma-0 red--text text-right">{{ `$ ${createReceiptForm.amount ? (createReceiptForm.amount*-1).toFixed(2) : 0}`}}</p>
                      <p class="ma-0 text-right">{{`$ ${((facturaPagar.total ? facturaPagar.total: 0) + (createReceiptForm.amount ? createReceiptForm.amount*-1 : 0)).toFixed(2)} `}}</p>
                    </div>
                    
                  </v-col>
                </v-row>
                <!-- propinas -->
                <v-row dense>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Tips"
                      v-model="createReceiptForm.tips"
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- Descripcion adicional para el pago -->
                <v-row dense>
                  <v-col xs="12">
                    <v-textarea
                      rows="2"
                      dense
                      label="Descripción o informacion adicional"
                      v-model="createReceiptForm.description"
                      :rules="[createFormRules.required]"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.receipt)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.receipt)"
              >
                Guardar y cerrar
              </v-btn>
              <!-- <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.receipt)"
              >
                Guardar Edicion
              </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

   

    <!-- DIALOG DELETE RECEIPT -->
    <v-dialog v-model="dialogDeleteReceipt" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar este Pago?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.receipt)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.receipt)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
  </div>
</template>

<script>
import {axiosN506} from '../api/axiosBd'
export default {
  props:{
    headers: {
      type: Object,
      required: true
    },
    boards: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      //loadings
      isLoading: false,
      loadingReceiptTable: false,
      //dialog delete
      dialogDeleteReceipt: false,
      //search
      recepitSearch: '',
      //records
      receipt_records: [],
      paymentMethod_records: [],
      folio_records: [],
      //form
      receiptDatePicker: false,
      btnEdithVisible: false,
      valid: false,
      dialogRecepit: false,
      createReceiptForm: {},
      //form rules
      createFormRules: {
        required: value => !!value || 'El campo es requerido.'
      },
      selectedRecord: null,
      facturaPagar: {}
    }
  },
  computed: {
    facturasPendientes() {
      return this.folio_records.filter(item => {
        if (item.invoice_folioId && item.invoice_folioId.bill_state == 'PENDIENTE') return item
      })
    },
    propinas(){
      if (this.createReceiptForm.amount > 0 && ((this.facturaPagar.total ? this.facturaPagar.total: 0) + (this.createReceiptForm.amount ? this.createReceiptForm.amount*-1 : 0)) < 0 ){
        this.createReceiptForm.tips = (((this.facturaPagar.total ? this.facturaPagar.total: 0) + (this.createReceiptForm.amount ? this.createReceiptForm.amount*-1 : 0))*-1).toFixed(2)
      } else {
         this.createReceiptForm.tips = 0
      }
      this.createReceiptForm.description = `Pago factura ${this.facturaPagar.invoice} de la reserva ${this.facturaPagar.booking_reference}
$ ${this.createReceiptForm.amount}`
    }
  },
  methods: {
    //Metodo para buscar el valor de la factura selecionada a pagar
    verificarFactura(item){
      let factura = this.folio_records.find((fact)=> fact.id == item)
      this.facturaPagar.total = factura.invoice_folioId.balance_due
      this.facturaPagar.invoice = factura.invoice_folioId.id
      this.facturaPagar.booking_reference = factura.reservationId.booking_reference
      this.facturaPagar.reservationId = factura.reservationId.id

    },
    folioTextName(item) {
      return `${item.reservationId.booking_reference} - Fact${item.invoice_folioId ? item.invoice_folioId.id : ' N/A'} 
        ${item.invoice_folioId.bill_toId ? item.invoice_folioId.bill_toId.first_name +" "+ item.invoice_folioId.bill_toId.last_name : item.reservationId.lead_traveler_name} 
        ${item.invoice_folioId.balance_due}`
    },
    summitForm(method, board){
      this.isLoading = true
      let error = false
      let form = {}

      if (board == this.boards.receipt) {
        if (!this.$refs.createReceiptForm.validate()) {
          error = true
        } else {  
          form = this.createReceiptForm
          if (this.createReceiptForm.amount >= this.facturaPagar.total) {               
            form.amount = this.facturaPagar.total
            form.invoice = this.facturaPagar.invoice
            form.reservation = this.facturaPagar.reservationId
          }

          form.amount = parseFloat(form.amount)
          form.tips = parseFloat(form.tips)
         
        }
      }

      if (!error) {
        this.$store.dispatch('verifyToken')
        console.log(form)
        if (method == 'POST') {
          axiosN506(`${board}/add`, {
            method: "POST",
            data: form,
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
            this.$store.dispatch('verifyToken')
            this.getData('folio')
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        } else if (method == 'PUT') {
          axiosN506(`${board}/update`, {
            method: "PUT",
            data: { id: this.selectedRecord, ...form },
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
            this.$store.dispatch('verifyToken')
            this.getData('folio')
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        }
      } else {
        //construir componente o hacer prototype para mensajes de error standar en la aplicacion
        this.$alert('Favor llenar todos los campos requeridos', 'Title', {
          confirmButtonText: 'OK',
          callback: action => {
            this.isLoading = false
          }
        })
      }
    },
    editItem(item, board){
      // this.loa
      this.btnEdithVisible = true
      this.selectedRecord = item.id
      
      if (board == this.boards.receipt) {
        this.createReceiptForm.payment_method = item.payment_method
        this.createReceiptForm.folio = item.folio
        this.createReceiptForm.date = item.date.substring(0,10)
        this.createReceiptForm.amount = item.amount
        this.createReceiptForm.description = item.description
        this.createReceiptForm.tips = item.tips
        this.dialogRecepit = true
      }

      
    },
    deleteItemConfirm(board) {
      this.isLoading = true

      axiosN506(`${board}/delete`, {
        method: "DELETE",
        data: { id: this.selectedRecord },
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then(() => {
        this.isLoading = false
        this.getData(board)
        this.closeDelete(board)
        this.getData('folio')
      }).catch((err) => {
        console.error(err)
        alert(`error: ${err.response ? err.response : err}`)
        this.isLoading = false          
      })
    },
    openDelete(item, board) {
      if (board == this.boards.receipt) this.dialogDeleteReceipt = true

      this.selectedRecord = item.id
    },
    openCreateForm(board) {
      if (board == this.boards.receipt) this.dialogRecepit = true
    },
    closeForm(board) {
      if (board == this.boards.receipt) {
        this.dialogRecepit = false
        this.$refs.createReceiptForm.resetValidation()
        this.createReceiptForm = {}
        this.facturaPagar = {}
        
      }
      this.btnEdithVisible = false
      this.isLoading = false
      this.selectedRecord = null
      
    },
    closeDelete(board) {
      this.selectedRecord = null

      if (board == this.boards.receipt) this.dialogDeleteReceipt = false
    },
    getData(board){
      this.loadingTable = true
      this.$store.dispatch('verifyToken')
      axiosN506({
        method: 'GET',
        url: `${board}/list`,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then((result) => {
        if (board == this.boards.receipt) this.loadingReceiptTable = false
        
        if (board == this.boards.receipt) this.receipt_records = result.data.data
        if (board == 'payment_method') this.paymentMethod_records = result.data.data
        if (board == 'folio') this.folio_records = result.data.data
      }).catch((err) => {
        console.error(err)
      });
    },
    convertToMoney(x) {
      return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    itemTextSubfamilyr(item){
      return `${item.name} ${item.familyId.name}`
    }
  },
  created() {
    this.getData(this.boards.receipt)
    this.getData('payment_method')
    this.getData('folio')
  }
}
</script>