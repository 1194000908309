<template>
 <v-container fluid class="py-3 px-5">
   <v-row>
     <v-col cols="12" class="pb-1">
       <p class="ma-1">
        PAGOS
       </p>
     </v-col>
   </v-row>
   <v-row>
     <v-col cols="12" class="pt-1">
       <ReceiptTable :headers="headers" :boards="boards">

       </ReceiptTable>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import ReceiptTable from '@/components/ReceiptTable.vue' 
export default {
  name: 'Receipt',
  components: {
    ReceiptTable
  },
  data() {
    return {
      headers: {
        receipt: [
          {
            text: 'No',
            align: 'start',
            value: 'id',
          },
          {
            text: 'Método pago',
            align: 'start',
            value: 'payment_methodId.name',
          },
          {
            text: 'Fecha',
            align: 'start',
            value: 'date',
          },
          {
            text: 'Total Pagado',
            align: 'end',
            value: 'amount',
          },
          {
            text: 'Tips',
            align: 'end',
            value: 'tips',
          },
          {
            text: 'Descripción',
            align: 'start',
            value: 'description',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ],
      },
      boards: {
        receipt: 'receipt',
      }
    }
  },
}
</script>

<style>

</style>